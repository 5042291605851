import { template as template_6152ef37ea9f445e992ae98b48b42c70 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6152ef37ea9f445e992ae98b48b42c70(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
