import { template as template_8f649c07220c4ed5930ee52fe5d7e6e5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_8f649c07220c4ed5930ee52fe5d7e6e5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
