import { template as template_da3f2e6d3b9d48468b0f98fa7273d759 } from "@ember/template-compiler";
const SidebarSectionMessage = template_da3f2e6d3b9d48468b0f98fa7273d759(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
