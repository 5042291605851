import { template as template_4daecd0e5f6a4deca0399bb6ff363970 } from "@ember/template-compiler";
const FKLabel = template_4daecd0e5f6a4deca0399bb6ff363970(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
