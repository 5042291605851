import { template as template_2d828193c99745728b31e3aacfd2a73f } from "@ember/template-compiler";
const WelcomeHeader = template_2d828193c99745728b31e3aacfd2a73f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
