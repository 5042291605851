import { template as template_f49251f37a974b419b9b9edf97cca61b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_f49251f37a974b419b9b9edf97cca61b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
